<template>
    <div class="default-image">
        <img src="../../assets/images/default.png" alt="" srcset="">
    </div>
</template>

<script>

export default ({

    data() {
      return {

        }
    },


})
</script>

<style scoped>

</style>
